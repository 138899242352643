import { createTheme } from '@mui/material/styles'


const theme = createTheme({
  palette: {
    primary: {
      main: "#003a5d",
    },
    secondary: {
      main: "#fff",
    },
  },
})

export default theme
