import React from 'react'

import ToastContext from '@/context/toastContext'
import { ACTIONS } from '@/provider/ToastProvider'

export function useAsyncRequest() {
  const { dispatch } = React.useContext(ToastContext)
  async function actionWrapper(
    func,
    { successMessage, errorMessage=true, showMessage } = {},
  ) {
    async function executeAction() {
      try {
        const res = await func()
        if (showMessage) {
          dispatch({
            type: ACTIONS.setToastMessage,
            payload: { message: successMessage || 'Success' },
          })
        }
        return res
      } catch (error) {
        console.log(error)
        if (errorMessage) {
          dispatch({
            type: ACTIONS.setToastMessage,
            payload: {
              message: error.message || 'Something went wrong!',
              type: 'error',
            },
          })
        }
        
        return false
      }
    }
    return await executeAction()
  }

  return {
    actionWrapper,
  }
}
