import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  Grid,
  Input,
  TextField,
  Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { AuthContext } from '@/provider/KeycloakProvider'
import { useAsyncRequest } from '@/utils/actionWrapper'
import platformApi from '@/api/platforms'

const initialFormData = {
  thumbnail: '',
  name: '',
  description: '',
  link: '',
}

const Edit = () => {
  const [formData, setFormData] = useState(initialFormData)
  const [imageSrc, setImageSrc] = useState(null)
  const [displayPosition, setDisplayPosition] = useState(0)

  const { id } = useParams()
  const { token } = useContext(AuthContext)
  const { update, getOne } = platformApi(token)

  const { actionWrapper } = useAsyncRequest()
  async function fetchData() {
    const item = await actionWrapper(() => getOne(id), {errorMessage: false})
    setImageSrc(item?.pictureUrl)
    setFormData({
      link: item?.link || "",
      name: item?.name || "",
      description: item?.description || "",
    })
    console.log(item)
    setDisplayPosition(item?.dashboardPosition || 0)
  }
  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, token])

  const onUpdate = async (e) => {
    e.preventDefault()
    if (formData.thumbnail) {
      const reader = new FileReader()
      let base64Data
      reader.onloadend = () => {
        base64Data = reader.result.split(',')[1]
        actionWrapper(() => { update(id, {
          link: formData.link,
          description: formData.description,
          name: formData.name,
          dashboardPosition: displayPosition,
          picture: {
            name: "image_upload",
            content: base64Data
          }
        })}, { showMessage: true })
      }
      reader.readAsDataURL(formData.thumbnail);
    } else {
      actionWrapper(() => { update(id, {
        link: formData.link,
        description: formData.description,
        dashboardPosition: displayPosition,
        name: formData.name,
      })}, { showMessage: true })
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    setImageSrc(URL.createObjectURL(file))
    setFormData({ ...formData, thumbnail: file })
  }
  return (
    <>
      <div className=" w-full">
        <div className="bg-white py-4 md:py-7 px-4 md:px-8 xl:px-10 w-full">
          <form onSubmit={onUpdate}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Update Platform
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Image <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <label htmlFor="thumbnail-upload">
                    <Input
                      id="thumbnail-upload"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                    <Button variant="contained" component="span" color='primary'>
                      Ajouter une image
                    </Button>
                  </label>
                  {imageSrc && (
                    <img
                      id="image-preview"
                      alt="Preview"
                      style={{
                        width: '100px',
                        height: 'auto',
                        marginTop: '10px',
                      }}
                      src={imageSrc}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                  Nom de la plateforme <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    name="name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Description
                  </Typography>
                  <TextField
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                  Lien <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    name="link"
                    required
                    value={formData.link}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                  Position sur le tableau de bord <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    name="Display Position"
                    type='number'
                    required
                    value={displayPosition}
                    onChange={(e) => setDisplayPosition(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Update Platform
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  )
}

export default Edit
