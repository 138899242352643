import React, { useContext } from 'react'
import logo from '@assets/images/logo-croped.png'
import header_bg_seamless from '@assets/images/footer-bg-seamless.png'
import footer from '@assets/images/footer.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/pro-solid-svg-icons'
import { AuthContext } from '@/provider/KeycloakProvider'
import { CircularProgress } from '@mui/material'

const MainLayout = ({ children }) => {
  const { logout, isAdmin, isAuthenticated } = useContext(AuthContext)

  return (
    <>
    {isAuthenticated? (
      <div>
      <nav className="w-100 bg-[#003a5d] ">
        <div className="flex justify-between p-4">
          <div className="flex flex-1 space-x-3  text-white">
            <a href="/" className="flex flex-col justify-center">
              <img
                src={logo}
                className="h-[30px] md:h-[60px] w-auto"
                alt="logo"
              ></img>
            </a>

            <div className=" border-l-2 border-dashed hidden md:block"></div>
            <a
              href="/"
              className=" my-auto  md:text-4xl font-semibold hidden md:block header-text"
            >
              Portail Adhérent
            </a>
          </div>
          <div className="break-normal md:w-1/6 w-1/2 text-white flex space-x-3">
            {isAdmin && (
              <a href="/admin" className="my-auto cursor-pointer ">
                <FontAwesomeIcon icon={faGear} />
              </a>
            )}

            <button onClick={logout} className=" my-auto cursor-pointer">
              Se déconnecter
            </button>
          </div>
        </div>
        <div
          style={{
            height: '25px',
            width: '100%',
            backgroundImage: `url("${header_bg_seamless}")`,
          }}
        ></div>
      </nav>
      <div className="px-10 pt-10 text-left">
        <h2 className=' text-[#003a5d]'>
          Bienvenue ! Tu trouveras ici l’ensemble des outils numériques des
          Scouts et Guides de France. L’accès à certains outils est restreint
          aux personnes ayant des fonctions précises.
        </h2>
      </div>
      <div className=" min-h-[75vh] md:p-10 lg:p-20 p-5">{children}</div>
      <hr></hr>
      <footer className="w-full flex flex-col p-5 text-[#003a5d] font-semibold text-lg space-y-3">
        <div className="flex justify-center">
          <img
            src={footer}
            alt="footer"
            className="h-[40px] md:h-[60px]"
          ></img>
        </div>
        <div className="flex space-x-10 justify-center ">
          <a
            href="https://sgdf.fr/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
          <a
            href="https://support.sgdf.fr/support/home"
            target="_blank"
            rel="noreferrer"
          >
            Support
          </a>
        </div>
      </footer>
    </div>
    ): (
      <div className='w-full h-screen flex justify-center'>
        <CircularProgress className='my-auto'/>
      </div>
    )}
      
    </>
  )
}

export default MainLayout
