import React, { useState, useContext } from 'react'
import {
  Button,
  FormControl,
  Grid,
  Input,
  TextField,
  Typography,
} from '@mui/material'
import { AuthContext } from '@/provider/KeycloakProvider'
import { useAsyncRequest } from '@/utils/actionWrapper'
import platformApi from '@/api/platforms'

const initialFormData = {
  thumbnail: '',
  platform_name: '',
  description: '',
  url: '',
}

const Create = () => {
  const [formData, setFormData] = useState(initialFormData)
  const [imageSrc, setImageSrc] = useState(null)
  const [displayPosition, setDisplayPosition] = useState(0)

  const { actionWrapper } = useAsyncRequest()

  const { token } = useContext(AuthContext)
  const { create } = platformApi(token)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    setImageSrc(URL.createObjectURL(file))
    setFormData({ ...formData, thumbnail: file })
  }

  const onCreate = async (e) => {
    e.preventDefault()
    if (formData.thumbnail) {
      const reader = new FileReader()
      let base64Data
      reader.onloadend = () => {
        base64Data = reader.result.split(',')[1]
        actionWrapper(() => { create({
          link: formData.url,
          description: formData.description,
          name: formData.platform_name,
          dashboardPosition: displayPosition,
          picture: {
            name: "image_upload",
            content: base64Data
          }
        })}, { showMessage: true })
        setFormData(initialFormData)
        setImageSrc(null)
        setDisplayPosition(0)
      }
      reader.readAsDataURL(formData.thumbnail);
    } else {
      actionWrapper(() => { create({
        link: formData.url,
        dashboardPosition: displayPosition,
        description: formData.description,
        name: formData.platform_name,
      })}, { showMessage: true })
      setFormData(initialFormData)
      setDisplayPosition(0)
    }
  }
  return (
    <>
      <div className=" w-full">
        <div className="bg-white py-4 md:py-7 px-4 md:px-8 xl:px-10 w-full">
          <form onSubmit={onCreate}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                Ajout d’une nouvelle plateforme
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                  Image <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <label htmlFor="thumbnail-upload">
                    <Input
                      id="thumbnail-upload"
                      type="file"
                      name='thumbnail-upload'
                      style={{ display: 'none' }}
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                    >
                      Ajouter une image
                    </Button>
                  </label>
                  {imageSrc && (
                    <img
                      id="image-preview"
                      alt="Preview"
                      accept=".jpeg, .png, .jpg"
                      style={{
                        width: '100px',
                        height: 'auto',
                        marginTop: '10px',
                      }}
                      src={imageSrc}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                  Nom de la plateforme <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    name="platform_name"
                    required
                    value={formData.platform_name}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Description
                  </Typography>
                  <TextField
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    multiline
                    rows={4}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                  Lien <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    name="url"
                    required
                    placeholder='https://google.com'
                    value={formData.url}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                  Position sur le tableau de bord <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    name="Display Position"
                    type="number"
                    required
                    value={displayPosition}
                    onChange={(e) => setDisplayPosition(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                Ajouter plateforme
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  )
}

export default Create
