import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import MainLayout from '@components/layouts/MainLayout'
import  { AuthContext }  from '@/provider/KeycloakProvider'
import { Navigate } from 'react-router-dom'

function ProtectedRoute({ children, adminOnly = false }) {
  const { isAdmin, token } = useContext(AuthContext)

  if (adminOnly) {
    if (token && !isAdmin) {
      return <Navigate to='/'  />
    }
  }
  return <MainLayout>{children}</MainLayout>
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ProtectedRoute
