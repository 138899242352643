import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import MainRoutes from '@/Routes'
import { ThemeProvider } from '@emotion/react'
import defautTheme from '@/theme/themes'

import ToastContext from '@/context/toastContext'
import useToastStore from '@/provider/ToastProvider'
import AuthContextProvider from '@/provider/KeycloakProvider'

import ToastMessage from '@/components/common/ToastMessage'
import { CircularProgress } from '@mui/material'

const App = () => {
  const { state, dispatch } = useToastStore()
  return (
    <>
      <AuthContextProvider>
        <ToastContext.Provider
          value={{
            state,
            dispatch,
          }}
        >
          <ToastMessage />
          <ThemeProvider theme={defautTheme}>
            <Suspense fallback={<CircularProgress />}>
              <Router>
                <MainRoutes />
              </Router>
            </Suspense>
          </ThemeProvider>
        </ToastContext.Provider>
      </AuthContextProvider>
    </>
  )
}

export default App
