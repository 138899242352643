import axiosInstance from '@/utils/axiosInstance'

const endpoint = 'applications'
const platformApi = (token) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}` 
  return {
    async update(id, data) {
      const res = await axiosInstance({
        url: `?id=${id}`,
        method: 'PUT',
        data,
      })
      return res.data
    },
    async create(data) {
      const res = await axiosInstance({
        url: `/`,
        method: 'POST',
        data,
      })
      return res.data
    },
    async get() {
      const res = await axiosInstance({
        url: `${endpoint}/`,
        method: 'GET',
      })
      return res.data
    },
    async getOne(id) {
      const res = await axiosInstance({
        url: `/${id}`,
        method: 'GET',
      })
      return res.data
    },
    async delete(id) {
      const res = await axiosInstance({
        url: `?id=${id}`,
        method: 'DELETE',
      })
      return res.data
    },
  }
}
export default platformApi
