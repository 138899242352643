import Keycloak from "keycloak-js";
import { createContext, useEffect, useState } from "react";

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URI,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

const keycloakInitOptions = {
  onLoad: "check-sso",
  checkLoginIframe: false,
  pkceMethod: "S256"
};

const keycloak = new Keycloak(keycloakConfig);

const defaultAuthContextValues = {
  isAuthenticated: false,
  username: "",
  token: "",
  logout: () => {},
};

export const AuthContext = createContext(defaultAuthContextValues);

const AuthContextProvider = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);


  useEffect(() => {
    async function initializeKeycloak() {
      try {
        const isAuthenticatedResponse = await keycloak.init(
          keycloakInitOptions
        );

        if (!isAuthenticatedResponse) {
          console.log(
            "user is not yet authenticated. forwarding user to login."
          );
          keycloak.login();
        }
        setAuthenticated(isAuthenticatedResponse);
      } catch {
        setAuthenticated(false);
      }
    }

    initializeKeycloak();
  }, []);

  useEffect(() => {
    async function loadProfile() {
      try {
        setToken(keycloak.token)
        const roleClientId = process.env.REACT_APP_KEYCLOAK_ROLE_CLIENT_ID || "intrassoc_sgdf_portail"
        const resourceAccess = keycloak.tokenParsed?.resource_access
        if (resourceAccess) {
          if (resourceAccess.hasOwnProperty(roleClientId)) {
            setIsAdmin(resourceAccess[roleClientId].roles.includes("Admin") )
          }
        }

      } catch (e){
        console.log("error trying to load the users profile", e);
      }
    }

    if (isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated]);

  const logout = () => {
    keycloak.logout();
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isAdmin, token, logout }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
