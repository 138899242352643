import React from 'react'
import ProtectedRoute from '@/components/guard/ProtectedRoute'

import HomePage from '@/views/Home'
import AdminPlatform from '@/views/Admin/Platform'
import CreatePlatform from '@/views/Admin/Platform/Create'
import EditPlatform from '@/views/Admin/Platform/Edit'

import { useRoutes } from 'react-router-dom'

const MainRoutes = () => {
  const routes = useRoutes([
    {
      path: '',
      element: (
        <ProtectedRoute>
          <HomePage />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: '/admin',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute adminOnly>
              <AdminPlatform />
            </ProtectedRoute>
          ),
          index: true,
        },
        {
          path: 'new',
          element: (
            <ProtectedRoute adminOnly>
              <CreatePlatform />
            </ProtectedRoute>
          ),
        },
        {
          path: 'edit/:id',
          element: (
            <ProtectedRoute adminOnly>
              <EditPlatform />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ])
  return routes
}

export default MainRoutes
